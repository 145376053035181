import { faBan, faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Button } from 'react-bootstrap'
import { GeneralDatatable } from '../../General/Tables/GeneralDatatable'

export const DamageTypeTable = (props) => {
    const columns = [
        {
            id: 'id',
            name: "Número",
            selector: (row) => row?.id,
            sortable: true,
        },
        {
            name: "Nombre",
            selector: (row) => row?.nombre,
            sortable: true,
        },
        {
            name: "Acciones",
            cell: (row) => {
                return (
                    <>
                        {
                            ![1, 2]?.includes(row.id) ? (
                                <Button size="sm" variant="warning" onClick={e => props.onDelete(row?.id)}>
                                    <FontAwesomeIcon icon={faEdit} />
                                </Button>
                            ) : (
                                <Button size="sm" variant="danger" title='No se puede editar'>
                                    <FontAwesomeIcon icon={faBan} />
                                </Button>
                            )

                        }
                    </>
                )
            }
        }
    ]
    return (
        <GeneralDatatable
            columns={columns}
            data={props.data}
            customProps={{ defaultSortFieldId: "id", defaultSortAsc: false, ...props }}
            showFilters={true}
        />
    )
}
