import React from 'react'
import { GeneralDatatable } from '../../General'

export const InvTable = (props) => {
    const columns = [
        {
            name: "Nombre",
            selector: (row) => row?.inventario?.nombre,
            sortable: true,
        },
        {
            name: "Cliente",
            selector: (row) => row?.cliente?.nombre,
            sortable: true,
        },
        {
            name: "Cantidad",
            selector: (row) => row?.cantidad,
            sortable: true,
        },
    ]
    return (
        <GeneralDatatable columns={columns} data={props.data} />
    )
}
