import React, { useEffect, useState } from 'react'
import {  useAxios } from '../../../contexts';
import { useParams } from 'react-router-dom';
import { Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { Loader } from '../../Loader';
import { InvTable } from './InvTable';

export const InventoryUserTable = () => {
    const { id } = useParams();
    const [inventory, setInventory] = useState([]);
    const [loading, setLoading] = useState(false);
    const { privateFetch } = useAxios();
    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = async () => {
        setLoading(true);
        const [
            { data: inventoryBd }
        ] = await Promise.all([
            privateFetch.get(`/api/inventory/getAssignedInv/${id}`)
        ])
        setInventory(inventoryBd?.inventory);
        setLoading(false);
    }
    return (
        <>
            <Row className="mb-2">
                <Col sm="auto">
                    <Button size="sm" variant="primary" onClick={getData}>
                        <FontAwesomeIcon icon={faSync} />
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col sm>
                    {
                        loading ? (<Loader />) : (
                            <InvTable data={inventory} />
                        )
                    }
                </Col>
            </Row>
        </>
    )
}
