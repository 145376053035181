import React, { useEffect, useState } from 'react'
import { Button, Col, FormControl, Modal, Row } from 'react-bootstrap'
import { useAxios } from '../../../contexts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSync } from '@fortawesome/free-solid-svg-icons';
import { DamageTypeTable } from '../../../components/controllers/inform/DamageTypeTable';
import { Loader } from '../../../components/Loader/Loader';
import { useAlert, useForm, useNotify } from '../../../hooks';

export const DamageTypes = () => {
    const [damages, setDamages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [editId, setEditId] = useState(null);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { privateFetch } = useAxios();
    const { notify, DelAlert } = useNotify();
    const { Alert } = useAlert();
    const { serialize } = useForm();

    useEffect(() => {
        document.title = "Tipos de daños"
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const getData = async () => {
        setLoading(true);
        const { data } = await privateFetch.get("/api/informs/getDamageType");
        setDamages(data?.damageType)
        setLoading(false);
    }
    const editDamage = async (id) => {
        setEditId(id);
        handleShow();
    }
    const processSave = async (ev) => {
        ev.preventDefault();
        const formData = serialize(ev?.target);
        await DelAlert({
            text: "Desea guardar o actualizar éste elemento, recuerde que una vez creado ya no se puede eliminar",
            onConfirm: async () => {
                const { data } = await privateFetch.post(
                    "/api/informs/saveOrEditDamageType",
                    {
                        ...formData,
                        id: editId,
                    }
                );
                notify(data);
                getData();
            },
            onCancel: () => {
                Alert({
                    type: "warning",
                    text: "Acción cancelada",
                });
            },
            confirmButtonText: editId ? "Actualizar" : "Guardar",
            icon: "warning"
        });
        setEditId(null);
        handleClose();
    }
    return (
        <>
            <Row>
                <Col sm="auto">
                    <Button size="sm" variant="primary" onClick={getData}>
                        <FontAwesomeIcon icon={faSync} />
                    </Button>
                </Col>
                <Col sm="auto">
                    <Button size="sm" variant="primary" onClick={handleShow}>
                        Crear tipo &nbsp;
                        <FontAwesomeIcon icon={faPlus} />
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col sm>
                    {loading ? (<Loader />) : (
                        <DamageTypeTable data={damages} onDelete={editDamage} />
                    )}
                </Col>
            </Row>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{editId ? "Editar" : "Crear"} tipo de daño</Modal.Title>
                </Modal.Header>
                <form onSubmit={processSave}>
                    <Modal.Body>
                        <Row>
                            <Col sm>
                                <label>Nombre</label>
                                <FormControl size='sm' name='nombre' required/>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button size='sm' variant="secondary" onClick={handleClose}>
                            Cerrar
                        </Button>
                        <Button size='sm' variant="primary" type='submit'>
                            Guardar
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}
