import React, { useState } from 'react'
import SimpleBar from 'simplebar-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, Nav, Image, Badge, Navbar, Button } from '@themesberg/react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { faFileInvoice, faFileInvoiceDollar, faSheetPlastic, faSignOutAlt, faTable, faTimes } from '@fortawesome/free-solid-svg-icons';
import logo from "../../assets/img/servicold-md-upper.png";
import { useAuth } from '../../contexts';
import { Logo } from '../General';
import { usePermissions } from '../../hooks';
import { GENERAR_FACTURAS, GESTION_BODEGAS, GESTION_CLIENTES, GESTION_DEPARTAMENTOS_CIUDADES, GESTION_EQUIPOS, GESTION_INVENTARIO, GESTION_PROCEDIMIENTOS, GESTION_USUARIOS, PANEL_ADMIN, TODOS_INFORMES, VER_CC } from '../../utils';


export const Sidebar = (props) => {
    const { user, LogOut } = useAuth();
    const { pathname } = useLocation();
    const [show, setShow] = useState(false);
    const { validate } = usePermissions()
    const showClass = show ? "show" : "";
    const onCollapse = () => setShow(!show);

    // eslint-disable-next-line
    const CollapsableNavItem = (props) => {
        const { eventKey, title, icon, children = null } = props;
        const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";
        return (
            <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
                <Accordion.Item eventKey={eventKey}>
                    <Accordion.Button as={Nav.Link} className="d-flex justify-content-between align-items-center">
                        <span>
                            <span className="sidebar-icon">
                                <FontAwesomeIcon icon={icon} />
                            </span>
                            <span className="sidebar-text">{title}</span>
                        </span>
                    </Accordion.Button>
                    <Accordion.Body className="multi-level">
                        <Nav className="flex-column">
                            {children}
                        </Nav>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        );
    };
    const NavItem = (props) => {
        const { title, link, external, target, icon, image, badgeText, badgeBg = "secondary", badgeColor = "primary" } = props;
        const classNames = badgeText ? "d-flex justify-content-start align-items-center justify-content-between" : "";
        const navItemClassName = link === pathname ? "active" : "";
        const linkProps = external ? { href: link } : { as: Link, to: link };
        return (
            <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
                <Nav.Link {...linkProps} target={target} className={classNames}>
                    <span>
                        {icon ? <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span> : null}
                        {image ? <Image src={image.image} width={image.width ?? 20} height={image.height ?? 20} className="sidebar-icon svg-icon" /> : null}
                        <span className="sidebar-text">{title}</span>
                    </span>
                    {badgeText ? (
                        <Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count ms-2">{badgeText}</Badge>
                    ) : null}
                </Nav.Link>
            </Nav.Item>
        );
    };
    return (
        <>
            <Navbar expand={false} collapseOnSelect variant="dark" className="navbar-theme-primary px-4 d-md-none">
                <Navbar.Brand className="me-lg-5" as={Link} to="/home" >
                    <Logo className="navbar-brand-light" />
                </Navbar.Brand>
                <Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={onCollapse}>
                    <span className="navbar-toggler-icon" />
                </Navbar.Toggle>
            </Navbar>
            <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
                <SimpleBar className={`collapse ${showClass} sidebar d-md-block text-white`}>
                    <div className="sidebar-inner px-4 pt-3">
                        <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
                            <div className="d-flex align-items-center">
                                <div className="user-avatar lg-avatar me-4">
                                    <Image src={""} className="card-img-top rounded-circle border-white" />
                                </div>
                                <div className="d-block">
                                    <h6>{user?.name}</h6>
                                    <Button variant="secondary" size="sm" onClick={LogOut} className="text-dark">
                                        <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />
                                        Cerrar sesión
                                    </Button>
                                </div>
                            </div>
                            <Nav.Link className="collapse-close d-md-none" onClick={onCollapse}>
                                <FontAwesomeIcon icon={faTimes} />
                            </Nav.Link>
                        </div>
                        <Nav className="flex-column pt-3 pt-md-0">
                            <NavItem link="/home" image={{ image: logo, width: 200, height: 50 }} />
                            <NavItem title={"Informe Técnico"} link="/inform" icon={faSheetPlastic} />
                            {
                                validate(user, GENERAR_FACTURAS) && (
                                    <NavItem title={<>
                                        Facturas
                                    </>} link="/invoices" icon={faFileInvoice} />
                                )
                            }
                            {
                                validate(user, VER_CC) && (
                                    <NavItem title={<>
                                        Cuentas de cobro
                                    </>} link="/caccount" icon={faFileInvoiceDollar} />
                                )
                            }
                            {
                                validate(user, PANEL_ADMIN) && (
                                    <CollapsableNavItem eventKey="device" title={"Administrativo"} icon={faTable}>
                                        {
                                            validate(user, GESTION_USUARIOS) && (
                                                <NavItem title={"Usuarios y roles"} link="/users" />
                                            )
                                        }
                                        {
                                            validate(user, GESTION_CLIENTES) && (
                                                <NavItem title={"Clientes"} link="/clients" />
                                            )
                                        }
                                        {
                                            validate(user, GESTION_INVENTARIO) && (
                                                <NavItem title={"Inventario"} link="/inventory" />
                                            )
                                        }
                                        {
                                            validate(user, GESTION_DEPARTAMENTOS_CIUDADES) && (
                                                <NavItem title={<>
                                                    Departamentos y <br />
                                                    ciudades
                                                </>} link="/depto-cities" />
                                            )
                                        }
                                        {
                                            validate(user, GESTION_PROCEDIMIENTOS) && (
                                                <NavItem title={<>
                                                    Procedimientos
                                                </>} link="/procedures" />
                                            )
                                        }
                                        {
                                            validate(user, GESTION_EQUIPOS) && (
                                                <NavItem title={<>
                                                    Equipos
                                                </>} link="/equipment" />
                                            )
                                        }
                                        {
                                            validate(user, GESTION_BODEGAS) && (
                                                <NavItem title={<>
                                                    Bodegas
                                                </>} link="/store" />
                                            )
                                        }
                                         {
                                            validate(user, TODOS_INFORMES) && (
                                                <NavItem title={<>
                                                    Tipos de daño
                                                </>} link="/inform/manage-damages" />
                                            )
                                        }
                                    </CollapsableNavItem>

                                )
                            }
                            {
                                /*<NavItem title="Volt React" link={Routes.Presentation.path} image={ReactHero} />

                            <NavItem title="Overview" link={Routes.DashboardOverview.path} icon={faChartPie} />
                            <NavItem external title="Messages" link="https://demo.themesberg.com/volt-pro-react/#/messages" target="_blank" badgeText="Pro" icon={faInbox} />
                            <NavItem title="Transactions" icon={faHandHoldingUsd} link={Routes.Transactions.path} />
                            <NavItem title="Settings" icon={faCog} link={Routes.Settings.path} />
                            <NavItem external title="Calendar" link="https://demo.themesberg.com/volt-pro-react/#/calendar" target="_blank" badgeText="Pro" icon={faCalendarAlt} />
                            <NavItem external title="Map" link="https://demo.themesberg.com/volt-pro-react/#/map" target="_blank" badgeText="Pro" icon={faMapPin} />

                            <CollapsableNavItem eventKey="tables/" title="Tables" icon={faTable}>
                                <NavItem title="Bootstrap Table" link={Routes.BootstrapTables.path} />
                            </CollapsableNavItem>

                            <CollapsableNavItem eventKey="examples/" title="Page Examples" icon={faFileAlt}>
                                <NavItem title="Sign In" link={Routes.Signin.path} />
                                <NavItem title="Sign Up" link={Routes.Signup.path} />
                                <NavItem title="Forgot password" link={Routes.ForgotPassword.path} />
                                <NavItem title="Reset password" link={Routes.ResetPassword.path} />
                                <NavItem title="Lock" link={Routes.Lock.path} />
                                <NavItem title="404 Not Found" link={Routes.NotFound.path} />
                                <NavItem title="500 Server Error" link={Routes.ServerError.path} />
                            </CollapsableNavItem>

                            <NavItem external title="Plugins" link="https://demo.themesberg.com/volt-pro-react/#/plugins/datatable" target="_blank" badgeText="Pro" icon={faChartPie} />

                            <Dropdown.Divider className="my-3 border-indigo" />

                            <CollapsableNavItem eventKey="documentation/" title="Getting Started" icon={faBook}>
                                <NavItem title="Overview" link={Routes.DocsOverview.path} />
                                <NavItem title="Download" link={Routes.DocsDownload.path} />
                                <NavItem title="Quick Start" link={Routes.DocsQuickStart.path} />
                                <NavItem title="License" link={Routes.DocsLicense.path} />
                                <NavItem title="Folder Structure" link={Routes.DocsFolderStructure.path} />
                                <NavItem title="Build Tools" link={Routes.DocsBuild.path} />
                                <NavItem title="Changelog" link={Routes.DocsChangelog.path} />
                            </CollapsableNavItem>
                            <CollapsableNavItem eventKey="components/" title="Components" icon={faBoxOpen}>
                                <NavItem title="Accordion" link={Routes.Accordions.path} />
                                <NavItem title="Alerts" link={Routes.Alerts.path} />
                                <NavItem title="Badges" link={Routes.Badges.path} />
                                <NavItem external title="Widgets" link="https://demo.themesberg.com/volt-pro-react/#/components/widgets" target="_blank" badgeText="Pro" />
                                <NavItem title="Breadcrumbs" link={Routes.Breadcrumbs.path} />
                                <NavItem title="Buttons" link={Routes.Buttons.path} />
                                <NavItem title="Forms" link={Routes.Forms.path} />
                                <NavItem title="Modals" link={Routes.Modals.path} />
                                <NavItem title="Navbars" link={Routes.Navbars.path} />
                                <NavItem title="Navs" link={Routes.Navs.path} />
                                <NavItem title="Pagination" link={Routes.Pagination.path} />
                                <NavItem tite="Popovers" link={Routes.Popovers.path} />
                                <NavItem title="Progress" link={Routes.Progress.path} />
                                <NavItem title="Tables" link={Routes.Tables.path} />
                                <NavItem title="Tabs" link={Routes.Tabs.path} />
                                <NavItem title="Toasts" link={Routes.Toasts.path} />
                                <NavItem title="Tooltips" link={Routes.Tooltips.path} />
                            </CollapsableNavItem>
                            <NavItem external title="Themesberg" link="https://themesberg.com" target="_blank" image={ThemesbergLogo} />
                            */}
                        </Nav>
                    </div>
                </SimpleBar>
            </CSSTransition>
        </>
    )
}
