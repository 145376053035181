import React, { useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { ManageRoles, UsuariosCRUD } from '../../../components';

export const Users = () => {
    const [key, setKey] = useState('users');

    return (
        <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3 mt-4"
        >
            <Tab eventKey="users" title="Usuarios">
                <UsuariosCRUD />
            </Tab>
            <Tab eventKey="roles" title="Roles">
                <ManageRoles />
            </Tab>
        </Tabs>
    )
}
